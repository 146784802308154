<template>
  <div class="manage-designer-add">
    <div class="container fluid">
      <fd-form @submit.prevent="submitForm">
        <div class="card p-3">
          <h2 class="mb-5">Add Designer</h2>

          <fd-form-section title="Account Credential">
            <fd-input
              v-model="designer.email"
              class="col-12 sm-col-6 md-col-3 px-1 mb-2"
              label="Email"
              name="email"
              type="email"
              :validators="[validator.required, validator.email]"
            >
            </fd-input>
            <fd-input
              v-model="designer.password"
              class="col-12 sm-col-6 md-col-3 px-1 mb-2"
              label="Password"
              name="password"
              :type="showPassword ? 'text' : 'password'"
              :validators="[validator.required, validator.password]"
            >
              <template #append-icon>
                <span
                  class="cursor-pointer"
                  @click="showPassword = !showPassword"
                >
                  <i
                    class="fa"
                    :class="{
                      'fa-eye': !showPassword,
                      'fa-eye-slash': showPassword
                    }"
                  ></i>
                </span>
              </template>
            </fd-input>
          </fd-form-section>
          <fd-form-section title="Personal Information">
            <fd-input
              v-model="designer.name"
              class="col-12 sm-col-6 md-col-3 px-1 mb-2"
              label="Name"
              name="name"
              type="text"
              :validators="[validator.required]"
            >
            </fd-input>
            <tel-input
              ref="tel"
              v-model="phoneInput"
              :tel.sync="designer.phoneNo"
              class="col-12 px-1 mb-2 d-block"
              label="Contact No."
              required
            >
            </tel-input>
          </fd-form-section>

          <div class="text-right p-2">
            <button class="btn main">Create</button>
          </div>
        </div>
      </fd-form>
    </div>
  </div>
</template>

<script>
import TelInput from "@/modules/User/components/TelInput";

// Validator
import {
  required,
  password,
  email,
  phone
} from "@/components/GlobalComponents/FormComponents/Validator/rules";
// API
import { designer as designerAPI } from "@/api";
// Model
import { DesignerModel } from "@/models";
// Composable
import useUserSubmitHandler from "@/modules/User/composables/useUserSubmitHandler";

export default {
  setup(props, context) {
    let toDesignerList = () => {
      context.root.$router.push({
        name: "ManageDesigners"
      });
    };
    const submitHandlerOptions = {
      role: "designer",
      successCallback: toDesignerList
    };
    const { handleCreateSuccess, handleSubmitError } = useUserSubmitHandler(
      context,
      submitHandlerOptions
    );

    return {
      handleCreateSuccess,
      handleSubmitError
    };
  },
  name: "AddDesigner",
  components: { TelInput },
  mixins: [],
  props: {},
  data: function () {
    return {
      phoneInput: "",
      designer: {
        email: "",
        passsword: "",

        name: "",
        phoneNo: {
          mobileISOCode: "",
          mobileCountryCode: "",
          mobileNumber: ""
        }
      },

      validator: {
        required: required,
        password: password,
        email: email,
        phone: phone
      },

      showPassword: false
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {
    this.init();
  },
  methods: {
    async init() {},
    async submitForm() {
      this.$store.commit("setIsLoading", true);
      try {
        let res = await designerAPI.createDesigner(
          DesignerModel.postDesignerPayload(this.designer)
        );
        this.$store.commit("setIsLoading", false);
        this.handleCreateSuccess(res);
        return;
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        this.handleSubmitError(error);
      }
    }
  }
};
</script>

<style lang="scss">
.manage-designer-add {
  form {
    @extend %formDesign;
  }
}
</style>
